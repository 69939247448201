import {
  combine,
  createStore,
  createEvent,
  createEffect,
  sample,
} from 'effector';
import dayjs from 'dayjs';
import { createForm } from 'effector-forms';
import rules from 'src/effector/forms/rules';
import mapValues from 'lodash/mapValues';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import includes from 'lodash/includes';
import * as api from 'src/logic/api';
import { assoc } from 'rambda';

const lastSixMonths = Array.from({ length: 180 }, (_, i) =>
  dayjs()
    .subtract(i, 'month')
    .startOf('month')
    .format('YYYY-MM-DD'),
);

const lastFiftyYears = Array.from({ length: 50 }, (_, i) =>
  dayjs()
    .subtract(i, 'year')
    .startOf('year')
    .format('YYYY'),
);

const setData = createEvent();
const login = createEvent();
const createAccount = createEvent();
const addAccountType = createEvent();
const resendConfirmation = createEvent();
const addAdditionalInfoProprietor = createEvent();
const addAdditionalInfoBusiness = createEvent();
const toPreviousStep = createEvent();
const toNextStep = createEvent();

const loginFx = createEffect().use(api.login);
const createAccountFx = createEffect().use(api.createAccount);
const addAccountTypeFx = createEffect().use(api.addAccountType);
const resendConfirmationFx = createEffect().use(api.resendConfirmation);
const validateEmailFx = createEffect().use(api.validateEmail);
const addAdditionalInfoFx = createEffect().use(api.addAdditionalInfo);

const $emailValidationMessage = createStore('');
const $currentEmail = createStore('').on(loginFx.done, (_, data) => {
  console.log('loginFx.done', data);
  return data?.result?.user?.email;
});
const $currentRole = createStore('')
  .on(loginFx.done, (_, data) => {
    console.log('data?.result?.user?.role_name', data?.result?.user?.role_name);
    return data?.result?.user?.role_name;
  })
  .on(addAccountTypeFx.done, (_, data) => {
    const role = data?.result?.roles.find(
      r => r === 'proprietor' || r === 'businesses',
    );
    return role;
  });

const $states = createStore([]).on(setData, (_, data) => data.states);
const $professions = createStore([]).on(setData, (_, data) => data.professions);
const $payFrequencies = createStore([]).on(
  setData,
  (_, data) => data.payFrequencies,
);
const $businessTypes = createStore([]).on(
  setData,
  (_, data) => data.businessTypes,
);
const $entityTypes = createStore([]).on(setData, (_, data) => data.entityTypes);
const $industries = createStore([]).on(setData, (_, data) => data.industries);
const $lastPaycheckDates = createStore(lastSixMonths);
const $dateEstablished = createStore(lastFiftyYears);

sample({
  clock: setData,
  fn: (_, data) => {
    console.log('setData', data);
  },
});

const getCurrentStep = (step, data) => {
  console.log('getCurrentStep', data);
  if (data.status === 'fail') return step;
  const user = data?.result?.user;
  if (!user?.confirmed) return 2;
  if (user?.role_name == null) return 3;
  if (user?.additional_info == null) return 4;

  window.location = '/';
  return 0;
};

const $currentStep = createStore(0)
  .on(setData, (_, data) => (includes(data.path, 'fleet_login') ? 0 : 1))
  .on(toPreviousStep, step => step - 1)
  .on(toNextStep, step => step + 1)
  .on(loginFx.finally, (currentStep, data) => getCurrentStep(currentStep, data))
  .on(createAccountFx.done, () => 2)
  .on(addAccountTypeFx.done, () => 4)
  .on(addAdditionalInfoFx.done, () => 0);

const $isLoading = createStore(false)
  .on(login, () => true)
  .on(loginFx, () => false)
  .on(createAccount, () => true)
  .on(createAccountFx.finally, () => false)
  .on(addAccountType, () => true)
  .on(addAccountTypeFx.finally, () => false)
  .on(resendConfirmationFx.finally, () => false);

const formatApiMessages = data => {
  console.log('apiMessage', data);
  const message = data?.error?.response?.data?.error || data?.result?.message;
  return isEmpty(message) ? '' : JSON.stringify(message);
};

const $apiMessage = createStore(null)
  .on(loginFx.finally, (_, data) => formatApiMessages(data))
  .on(createAccountFx.finally, (_, data) => formatApiMessages(data))
  .on(resendConfirmationFx.finally, (_, data) => formatApiMessages(data))
  .on(addAccountTypeFx.finally, (_, data) => formatApiMessages(data))
  .on(addAdditionalInfoFx.finally, (_, data) => formatApiMessages(data));

const loginFields = {
  email: {
    init: '',
    rules: [rules.email()],
  },
  password: {
    init: '',
    rules: [rules.required()],
  },
};

const loginForm = createForm({
  fields: {
    ...loginFields,
  },
});

const $isValidLoginForm = combine(loginForm.$isValid, a => a);

const $loginDataToSend = combine(
  {
    loginFormData: loginForm.$values,
  },
  ({ loginFormData }) => ({ ...loginFormData }),
);

sample({
  clock: login,
  source: $loginDataToSend,
  target: loginFx,
});

const createAccountFields = {
  email: {
    init: '',
    rules: [rules.email()],
  },
  password: {
    init: '',
    rules: [rules.required()],
  },
  password_confirmation: {
    init: '',
  },
  payment_type: {
    init: '',
    rules: [rules.required()],
  },
};

const createAccountForm = createForm({
  fields: {
    ...createAccountFields,
  },
});

const $isValidCreateAccountForm = combine(createAccountForm.$isValid, a => a);

sample({
  clock: createAccountForm.fields.email.onBlur,
  source: createAccountForm.fields.email.$value,
  filter: value => !isEmpty(value),
  fn: email => ({ email }),
  target: validateEmailFx,
});

sample({
  source: validateEmailFx.done,
  fn: data => {
    if (data.result.didYouMean)
      return `Did you mean to enter ${data.result.didYouMean}?`;
    if (data.result.status === 'invalid')
      return 'Please double check your email is correct.';
    return '';
  },
  target: $emailValidationMessage,
});

const $createAccountDataToSend = combine(
  {
    createAccountFormData: createAccountForm.$values,
  },
  ({ createAccountFormData }) => ({
    data: createAccountFormData,
  }),
);

sample({
  clock: createAccount,
  source: $createAccountDataToSend,
  target: createAccountFx,
});

sample({
  clock: createAccountFx.done,
  fn: data => {
    return data.result.user.email;
  },
  target: $currentEmail,
});

const addAccountTypeFields = {
  role: {
    init: '',
    rules: [rules.required()],
  },
};

const addAccountTypeForm = createForm({
  fields: {
    ...addAccountTypeFields,
  },
});

const $isValidAddAccountTypeForm = combine(addAccountTypeForm.$isValid, a => a);

const $addAccountTypeDataToSend = combine(
  {
    addAccountTypeFormData: addAccountTypeForm.$values,
    currentEmail: $currentEmail,
  },
  ({ addAccountTypeFormData, currentEmail }) => ({
    data: { ...addAccountTypeFormData, email: currentEmail },
  }),
);

const addAdditionalInfoProprietorFields = {
  firstName: {
    init: '',
    rules: [rules.required()],
  },
  lastName: {
    init: '',
    rules: [rules.required()],
  },
  company: {
    init: '',
    rules: [rules.required()],
  },
  vehicleNumber: {
    init: 0,
    rules: [rules.required()],
  },
  streetAddress: {
    init: '',
    rules: [rules.required()],
  },
  streetAddress2: {
    init: '',
  },
  city: {
    init: '',
    rules: [rules.required()],
  },
  state_id: {
    init: '',
    rules: [rules.required()],
  },
  state_abbr: {
    init: '',
  },
  zipcode: {
    init: '',
    rules: [rules.required(), rules.minLength(5)],
  },
  profession: {
    init: '',
    rules: [rules.required()],
  },
  phone: {
    init: '',
    rules: [rules.required(), rules.minLength(10)],
  },
  ssn_itin: {
    init: '',
    rules: [
      rules.required(),
      {
        name: 'ssn-format',
        validator: value =>
          /^[A-Za-z0-9]{3}-[A-Za-z0-9]{2}-[A-Za-z0-9]{4}$/.test(value),
        errorText: 'Invalid SSN/ITIN format (XXX-XX-XXXX)',
      },
    ],
  },
  grossMonthlyIncome: {
    init: '',
    rules: [rules.required()],
  },
  payFrequencyDays: {
    init: 0,
    rules: [rules.required()],
  },
  lastPaycheckDate: {
    init: null,
    rules: [rules.required()],
  },
};

const addAdditionalInfoProprietorForm = createForm({
  fields: {
    ...addAdditionalInfoProprietorFields,
  },
});

const $isValidAddAdditionalInfoProprietorForm = combine(
  addAdditionalInfoProprietorForm.$isValid,
  a => a,
);

const $addAdditionalInfoProprietorToSend = combine(
  {
    addAdditionalInfoFormData: addAdditionalInfoProprietorForm.$values,
    currentEmail: $currentEmail,
  },
  ({ addAdditionalInfoFormData, currentEmail }) => ({
    data: { info: { ...addAdditionalInfoFormData }, email: currentEmail },
  }),
);

const addAdditionalInfoBusinessFields = {
  companyName: {
    init: '',
    rules: [rules.required()],
  },
  dba: {
    init: '',
    rules: [rules.required()],
  },
  businessStreetAddress: {
    init: '',
    rules: [rules.required()],
  },
  suiteOrFloor: {
    init: '',
    rules: [rules.required()],
  },
  city: {
    init: '',
    rules: [rules.required()],
  },
  state_id: {
    init: '',
    rules: [rules.required()],
  },
  state_abbr: {
    init: '',
  },
  zipcode: {
    init: '',
    rules: [rules.required(), rules.minLength(5)],
  },
  businessPhoneNumber: {
    init: '',
    rules: [rules.required(), rules.minLength(10)],
  },
  totalAnnualRevenue: {
    init: '',
    rules: [rules.required()],
  },
  businessType: {
    init: '',
    rules: [rules.required()],
  },
  entityType: {
    init: '',
    rules: [rules.required()],
  },
  industry: {
    init: '',
    rules: [rules.required()],
  },
  ein: {
    init: '',
    rules: [
      rules.required(),
      {
        name: 'ssn-format',
        validator: value => /^[0-9]{2}-[0-9]{7}$/.test(value),
        errorText: 'Invalid EIN format (XX-XXXXXXX)',
      },
    ],
  },
  dateEstablished: {
    init: null,
    rules: [rules.required()],
  },
  vehicleNumber: {
    init: 0,
    rules: [
      rules.required(),
      {
        name: 'non-negative',
        validator: value => Number.isInteger(value) && value >= 0,
        errorText: 'Vehicle number must be 0 or greater',
      },
    ],
  },
};

const addAdditionalInfoBusinessForm = createForm({
  fields: {
    ...addAdditionalInfoBusinessFields,
  },
});

const $isValidAddAdditionalInfoBusinessForm = combine(
  addAdditionalInfoBusinessForm.$isValid,
  a => a,
);

const $addAdditionalInfoBusinessToSend = combine(
  {
    addAdditionalInfoFormData: addAdditionalInfoBusinessForm.$values,
    currentEmail: $currentEmail,
  },
  ({ addAdditionalInfoFormData, currentEmail }) => ({
    data: { info: { ...addAdditionalInfoFormData }, email: currentEmail },
  }),
);

sample({
  clock: addAccountType,
  source: $addAccountTypeDataToSend,
  target: addAccountTypeFx,
});

sample({
  clock: resendConfirmation,
  source: $currentEmail.map(email => ({ email })),
  target: resendConfirmationFx,
});

const fieldNames = [
  'email',
  'password',
  'password_confirmation',
  'payment_type',
];

const errors = fieldNames.reduce(
  (acc, name) => assoc(name, createAccountForm.fields[name].$firstError, acc),
  [],
);

const $errors = combine(errors, a => mapValues(a, v => get(v, 'errorText')));

// const additionalInfoErrors = fieldNames.reduce(
//   (acc, name) =>
//     assoc(name, addAdditionalInfoBusinessesForm.fields[name].$firstError, acc),
//   [],
// );
//
// const $additionalInfoErrors = combine(additionalInfoErrors, a =>
//   mapValues(a, v => get(v, 'errorText')),
// );

sample({
  clock: addAdditionalInfoProprietor,
  source: $addAdditionalInfoProprietorToSend,
  target: addAdditionalInfoFx,
});
sample({
  clock: addAdditionalInfoBusiness,
  source: $addAdditionalInfoBusinessToSend,
  target: addAdditionalInfoFx,
});

export const stores = {
  $loginFormValues: loginForm.$values,
  $createAccountFormValues: createAccountForm.$values,
  $addAccountTypeFormValues: addAccountTypeForm.$values,
  $addAdditionalInfoBusinessFormValues: addAdditionalInfoBusinessForm.$values,
  $addAdditionalInfoProprietorFormValues:
    addAdditionalInfoProprietorForm.$values,
  $isValidLoginForm,
  $isValidCreateAccountForm,
  $isValidAddAccountTypeForm,
  $isValidAddAdditionalInfoBusinessForm,
  $isValidAddAdditionalInfoProprietorForm,
  $errors,
  $emailValidationMessage,
  $apiMessage,
  $currentStep,
  $states,
  $professions,
  $payFrequencies,
  $businessTypes,
  $entityTypes,
  $industries,
  $lastPaycheckDates,
  $dateEstablished,
  $currentRole,
  $isLoading,
};

export const actions = {
  login,
  loginFx,
  loginFormActions: loginForm,
  createAccount,
  createAccountFx,
  createAccountFormActions: createAccountForm,
  addAccountType,
  addAccountTypeFx,
  addAccountTypeFormActions: addAccountTypeForm,
  addAdditionalInfoProprietor,
  addAdditionalInfoBusiness,
  addAdditionalInfoFx,
  addAdditionalInfoProprietorFormActions: addAdditionalInfoProprietorForm,
  addAdditionalInfoBusinessFormActions: addAdditionalInfoBusinessForm,
  resendConfirmation,
  toPreviousStep,
  toNextStep,
  setData,
};

export const store = combine(stores);
