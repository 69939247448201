import qs from 'qs';
import http from '../http';

export const fetchOrder = async orderNumber => {
  const { data } = await http.get(`/orders/${orderNumber}`);
  return data;
};

export const orderVerification = async params => {
  const { data } = await http.get(`verify_order?${qs.stringify(params)}`);
  const error = 'error_message' in data && data;
  if (error) {
    throw new Error(error.error_message);
  }
  return data;
};

export const createIssue = async params => {
  const { data } = await http.post('shipment_issues', params);
  return data;
};

export const updateAddress = async params => {
  const { data } = await http.patch(`checkout/update/address`, params);
  return data;
};

export const updateDelivery = async params => {
  const { data } = await http.patch(`checkout/update/delivery`, params);
  return data;
};

export const findPickUpLocations = async zip => {
  const { data } = await http.get(`/locations/zip/${zip}`);
  const error = data.errors.find(({ type }) => type === 'ERROR');
  if (error) {
    throw new Error(error.description);
  }
  return data.locations.map((item, i) => ({ ...item, id: data.ids[i].id }));
};

export const findInstallerLocations = async zip => {
  const { data } = await http.get(`/locations/installers/${zip}`);
  return data;
};

export const updateInflaterQuantity = async quantity => {
  const { data } = await http.post('/inflater/create_or_update', { quantity });
  return data;
};

export const removeInflaterFromCart = async () => {
  const { data } = await http.delete('/inflater/destroy');
  return data;
};

export const cancelOrder = async params => {
  const data = await http.post('/api/v1/cancellations/cancel_order', params);
  return data;
};

export const getExtendPlans = async productSku => {
  const { data } = await http.get(
    `/api/v1/extend/plans?product_sku=${productSku}`,
  );
  return data;
};

export const fetchOrderByNumberAndEmail = async params => {
  const { data } = await http.get(
    `/api/v1/order_returns/get_order?${qs.stringify(params)}`,
  );
  return data;
};

export const createOrderReturn = async params => {
  const { data } = await http.patch(`/api/v1/order_returns/create`, params, {
    timeout: 60000,
  });
  return data;
};

export const addPromoCode = async params => {
  const { data } = await http.patch(
    `/api/v1/order_returns/add_promo_code`,
    params,
    { timeout: 60000 },
  );
  return data;
};

export const submitPayment = async params => {
  const { data } = await http.patch(
    `/api/v1/order_returns/save_payment`,
    params,
    { timeout: 60000 },
  );
  return data;
};

export const fetchWarrantyClaimOrder = async params => {
  const { data } = await http.get(
    `/api/v1/warranty_claims/order?${qs.stringify(params)}`,
  );
  return data;
};

export const submitWarrantyClaimZendeskForm = async params => {
  const { data } = await http.post(`/api/v1/warranty_claims`, params, {
    timeout: 60000,
  });
  return data;
};

export const fetchYearsByMake = async ({ make }) => {
  const { data } = await http.get(`/get_cyear_by_make2/${make}`);
  return data;
};

export const fetchModelsByYear = async ({ make, year }) => {
  const { data } = await http.get(`/get_model/${make}&${year}`);
  return data;
};

export const getAvailableSizes = async productId => {
  const { data } = await http.get(`/api/v1/product_alternates/${productId}`);
  return data;
};

export const fetchSetiing = async () => {
  const { data } = await http.get(`/api/v1/settings`);
  return data;
};

export const updateCheckoutState = async params => {
  const { data } = await http.put('api/v1/checkout/', params);
  return data;
};

export const confirmQuantity = async params => {
  const { data } = await http.patch(
    'api/v1/checkout/confirm_line_items_quantity',
    params,
  );
  return data;
};

export const checkDistance = async params => {
  const { data } = await http.get(
    `api/v1/checkout/check_distance?${qs.stringify(params)}`,
  );
  return data;
};

export const createAllstate = async params => {
  const { data } = await http.post('api/v1/checkout/allstate', params);
  return data;
};

export const setZip = async params => {
  const { data } = await http.patch('api/v1/search/zip', params);

  return data;
};

export const getPlyTypes = async params => {
  const { data } = await http.get(
    `api/v1/search/size/tires/ply_types?${qs.stringify(params)}`,
  );
  return data;
};

export const createExtend = async params => {
  const { data } = await http.post('api/v1/checkout/extend', params);
  return data;
};

export const validateEmail = async params => {
  const { data } = await http.get(
    `api/v1/email/validate?${qs.stringify(params)}`,
  );
  return data;
};

export const ensureOrderConsistency = async () => {
  const { data } = await http.patch(
    `${window.location.origin}/api/v1/checkout/ensure_order_consistency`,
  );
  return data;
};

export const notifyAboutSomething = async params => {
  const { data } = await http.post('api/v1/notifications', params);
  return data;
};

export const createAhoyEvent = async params => {
  const { data } = await http.post('api/v1/ahoys', params);
  return data;
};

export const fetchCheckoutViewedAnalytics = async () => {
  const { data } = await http.get('/api/v1/analytics/checkout_viewed');
  return data;
};

export const fetchCartViewedAnalytics = async () => {
  const { data } = await http.get('/api/v1/analytics/cart_viewed');
  return data;
};

export const createAccount = async params => {
  const { data } = await http.post('api/v1/account/', params);
  return data;
};

export const addAccountType = async params => {
  const { data } = await http.post('api/v1/account/account_type', params);
  return data;
};

export const resendConfirmation = async params => {
  const { data } = await http.post(
    '/api/v1/account/resend_confirmation',
    params,
  );
  return data;
};

export const addAdditionalInfo = async params => {
  const { data } = await http.post('api/v1/account/additional_info', params);
  return data;
};

export const login = async params => {
  const { data } = await http.post('api/v1/account/login', params);
  return data;
};
